import { bff } from '../../entitlements/backend/bff'

export function useCompanyEntitlements(companyId: string) {
    const { data: companyEntitlementsTableInfo, isLoading: isLoading } =
        bff.companyEntitlements.getCompanyEntitlementsTableInfo.useQuery({ companyId })
    return {
        companyEntitlementsTableInfo: companyEntitlementsTableInfo,
        isLoading: isLoading,
    }
}
