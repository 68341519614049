import { Card, Tag } from 'antd'
import { formatDate } from 'packages/dates/dates'
import { useOddDetailContext } from './context-detail'
import { bff } from './bff-hooks'

const OffboardingDetails: React.FC = () => {
    const { caseId } = useOddDetailContext()
    const { data: metadata } = bff.offboardingDetails.getOffboardingDetails.useQuery({ caseId })

    const offboardingDetailsParsed =
        metadata?.offboardingDetails && metadata?.offboardingDetails.length
            ? JSON.parse(metadata?.offboardingDetails)
            : undefined

    if (!offboardingDetailsParsed) {
        return null
    }

    return (
        <Card
            title="Offboarding Details"
            style={{ position: 'sticky', top: 16 }}
            styles={{ body: { padding: '8px 24px' } }}
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <strong>Category:</strong> <Tag>{offboardingDetailsParsed?.category}</Tag>
                </div>
                <div>
                    <strong>Reasons:</strong>{' '}
                    {offboardingDetailsParsed?.hasReasons && (
                        <>
                            {offboardingDetailsParsed?.reasons.map((reason: string) => (
                                <Tag key={reason}>{reason}</Tag>
                            ))}
                        </>
                    )}
                </div>
                <div>
                    <strong>Notes:</strong> {offboardingDetailsParsed?.notes}
                </div>
                <div>
                    <strong>Initiated by:</strong> {offboardingDetailsParsed?.initiatedBy}
                </div>
                <div>
                    <strong>Initiated at:</strong>{' '}
                    {formatDate(offboardingDetailsParsed?.initiatedAt)}
                </div>
                {metadata?.offboardingState && metadata?.offboardingState === 'COMPLETED' && (
                    <>
                        {offboardingDetailsParsed?.completedBy && (
                            <div>
                                <strong>Completed by:</strong>{' '}
                                {offboardingDetailsParsed?.completedBy}
                            </div>
                        )}
                        {offboardingDetailsParsed?.completedAt && (
                            <div>
                                <strong>Completed at:</strong>{' '}
                                {formatDate(offboardingDetailsParsed?.completedAt)}
                            </div>
                        )}
                    </>
                )}
            </div>
        </Card>
    )
}

export default OffboardingDetails
