import { Descriptions, Table, Typography, Tag } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import type { PresetColorType } from 'antd/lib/_util/colors'

import config from 'config'

import type { EmployeeResponse, Role, DemoAccountResponse } from 'types/demo-accounts'
import formatCurrency from 'utils/format-currency'

import { countries } from 'countries-list'
import type { SupportedCountry } from 'types/countries'

const SMS_OTP = config.auth.smsOtp

const { Text } = Typography

interface Props {
    demoAccount?: DemoAccountResponse
}

const getRoleColor = (role: Role): PresetColorType => {
    if (role === 'OWNER') {
        return 'gold'
    } else {
        return 'blue'
    }
}

const getCountryName = (country: SupportedCountry) => {
    const { emoji, name } = countries[country]
    return `${name} ${emoji}`
}

// to be removed in https://linear.app/pleo/issue/DEMO-411/
export const DemoAccountModal = ({ demoAccount }: Props) => {
    const columns: ColumnsType<EmployeeResponse> = [
        {
            title: 'Role',
            dataIndex: 'role',
            render: (role: Role) => <Tag color={getRoleColor(role)}>{role}</Tag>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email: string) => <Text copyable>{email}</Text>,
        },
        {
            title: 'Passcode',
            dataIndex: 'passcode',
            render: (passcode: string) => <Text copyable>{passcode}</Text>,
        },
        {
            title: 'SMS passcode',
            render: () => <Text copyable>{SMS_OTP}</Text>,
        },
    ]

    const balance = demoAccount?.company.balance
    const country = demoAccount?.company.country
    return (
        <div>
            <Descriptions title={demoAccount?.company.name}>
                <Descriptions.Item label="Country">
                    {country ? getCountryName(country) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Wallet Balance">
                    {balance ? formatCurrency(balance.value, balance.currency) : '-'}
                </Descriptions.Item>
            </Descriptions>
            <Table
                dataSource={demoAccount?.employees}
                columns={columns}
                rowKey="email"
                loading={!demoAccount}
            />
        </div>
    )
}
