import { useEffect, useState } from 'react'
import { Skeleton, Space, Statistic } from 'antd'
import styled from 'styled-components'

import { bff } from './bff-hooks'
import type { CaseCountsResponse, CaseCountsPerWaitStatusResponse } from './index.bff'
import { mapUrlParamsToSearchParams } from 'pages/compliance/odd/utils'
import { OddListTab } from './types'

const CaseCount = ({ title, value }: { title: string; value: number }) => {
    return (
        <CaseCountContainer>
            <Statistic
                title={title}
                value={value}
                style={{ minWidth: 168 }}
                valueStyle={{ fontSize: 16, fontWeight: 700 }}
            />
        </CaseCountContainer>
    )
}

export const CaseCountsBar = ({ activeTab }: { activeTab: OddListTab }) => {
    const windowLocation = window.location.search

    const [searchParams, setSearchParams] = useState(mapUrlParamsToSearchParams())

    useEffect(() => {
        setSearchParams(mapUrlParamsToSearchParams())
    }, [windowLocation])

    const { data: allCounts, isLoading: isAllCountsLoading } =
        bff.caseCounts.getCounts.useQuery(searchParams)
    const { data: countsWaitingForCustomer, isLoading: isCountsWaitingForCustomerLoading } =
        bff.caseCounts.getTotalCasesPerWaitStatus.useQuery(searchParams)

    const counts = getCountsForActiveTab({ allCounts, countsWaitingForCustomer, activeTab })

    return (
        <StyledSpace>
            {isAllCountsLoading || isCountsWaitingForCustomerLoading ? (
                <Skeleton.Node active style={{ width: 185, height: 70, borderRadius: 8 }} />
            ) : (
                counts.map((count) => <CaseCount key={count.title} {...count} />)
            )}
        </StyledSpace>
    )
}

function getCountsForActiveTab({
    allCounts,
    countsWaitingForCustomer,
    activeTab,
}: {
    allCounts?: CaseCountsResponse
    countsWaitingForCustomer?: CaseCountsPerWaitStatusResponse
    activeTab: OddListTab
}) {
    if (!allCounts) {
        return []
    }

    switch (activeTab) {
        case OddListTab.new:
            return [
                { title: 'New', value: allCounts.newCasesCount },
                { title: 'Overdue', value: allCounts.overdueCasesNewCasesCount },
            ]
        case OddListTab.informationReceived:
            return [
                { title: 'Information Received', value: allCounts.informationReceivedCasesCount },
                { title: 'Overdue', value: allCounts.overdueCasesInformationReceivedCasesCount },
            ]
        case OddListTab.inProgress:
            return [{ title: 'In Progress', value: allCounts.inProgressCasesCount }]
        case OddListTab.waitingForCustomer:
            return [
                { title: 'Waiting for Customer', value: allCounts.waitingForCustomerCasesCount },
                {
                    title: 'Initial Emails Sent',
                    value: countsWaitingForCustomer?.data?.INITIAL || 0,
                },
                {
                    title: 'Reminder 1 Emails Sent',
                    value: countsWaitingForCustomer?.data?.REMINDER_1 || 0,
                },
                {
                    title: 'Reminder 2 Emails Sent',
                    value: countsWaitingForCustomer?.data?.REMINDER_2 || 0,
                },
            ]
        case OddListTab.walletBlockedDue:
            return [
                { title: 'Waiting for Customer', value: allCounts.waitingForCustomerCasesCount },
                {
                    title: 'Initial Emails Sent',
                    value: countsWaitingForCustomer?.data?.INITIAL || 0,
                },
                {
                    title: 'Reminder 1 Emails Sent',
                    value: countsWaitingForCustomer?.data?.REMINDER_1 || 0,
                },
                {
                    title: 'Reminder 2 Emails Sent',
                    value: countsWaitingForCustomer?.data?.REMINDER_2 || 0,
                },
            ]
        case OddListTab.walletBlocked:
            return [{ title: 'Wallet Blocked', value: allCounts.walletBlockedCasesCount }]
        case OddListTab.offboardingInitiated:
            return [
                { title: 'Offboarding Initiated', value: allCounts.offboardingInitiatedCasesCount },
            ]
        case OddListTab.offboardingCompleted:
            return [
                { title: 'Offboarding Completed', value: allCounts.offboardingCompletedCasesCount },
            ]
        case OddListTab.oddCompleted:
            return [{ title: 'ODD Completed', value: allCounts.oddCompletedCasesCount }]
    }
}

const StyledSpace = styled(Space)`
    margin-bottom: 16px;
    width: 100%;
`

const CaseCountContainer = styled.div`
    border: 1px solid #d9d9d9;
    padding: 8px;
    border-radius: 8px;
`
