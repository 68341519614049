import { OddListTab } from 'features/odd/types'
import { SearchCasesStateItem } from 'bff/moons/generated/case-manager'

export enum CustomActionId {
    SCHEDULE_REQUEST_INFO_EMAILS = 'SCHEDULE_REQUEST_INFO_EMAILS',
    DELAY_EMAIL_SCHEDULE = 'DELAY_EMAIL_SCHEDULE',
    SEND_WALLET_BLOCKED_EMAIL = 'SEND_WALLET_BLOCKED_EMAIL',
}

export const oddListTabToLabelMapper = [
    {
        key: OddListTab.new,
        tab: 'New',
    },
    {
        key: OddListTab.informationReceived,
        tab: 'Information Received',
    },
    {
        key: OddListTab.inProgress,
        tab: 'In Progress',
    },
    {
        key: OddListTab.waitingForCustomer,
        tab: 'Waiting for Customer',
    },
    {
        key: OddListTab.walletBlockedDue,
        tab: 'Wallet Blocked Due',
    },
    {
        key: OddListTab.walletBlocked,
        tab: 'Wallet Blocked',
    },
    {
        key: OddListTab.offboardingInitiated,
        tab: 'Offboarding Initiated',
    },
    {
        key: OddListTab.offboardingCompleted,
        tab: 'Offboarding Completed',
    },
    {
        key: OddListTab.oddCompleted,
        tab: 'ODD Completed',
    },
]

export const tabNameToCasesStateMapper: { [key: string]: SearchCasesStateItem[] } = {
    [OddListTab.new]: [SearchCasesStateItem.NEW],
    [OddListTab.informationReceived]: [SearchCasesStateItem.INFORMATION_RECEIVED],
    [OddListTab.inProgress]: [SearchCasesStateItem.IN_PROGRESS],
    [OddListTab.waitingForCustomer]: [SearchCasesStateItem.WAITING_FOR_CUSTOMER],
    [OddListTab.walletBlockedDue]: [SearchCasesStateItem.WAITING_FOR_CUSTOMER], // Note: This is a special case
    [OddListTab.walletBlocked]: [SearchCasesStateItem.WALLET_BLOCKED],
    [OddListTab.offboardingInitiated]: [SearchCasesStateItem.OFFBOARDING_INITIATED],
    [OddListTab.offboardingCompleted]: [SearchCasesStateItem.OFFBOARDING_COMPLETED],
    [OddListTab.oddCompleted]: [SearchCasesStateItem.ODD_COMPLETED],
}

export enum FilterParamType {
    country = 'country',
    state = 'state',
    dueDateFrom = 'dueDateFrom',
    dueDateTo = 'dueDateTo',
    lastStateChangeAtFrom = 'lastStateChangeAtFrom',
    lastStateChangeAtTo = 'lastStateChangeAtTo',
    assignedAtFrom = 'assignedAtFrom',
    assignedAtTo = 'assignedAtTo',
    firstAssignedAtFrom = 'firstAssignedAtFrom',
    firstAssignedAtTo = 'firstAssignedAtTo',
    assigneeId = 'assigneeId',
    lastAssigneeId = 'lastAssigneeId',
    entityId = 'entityId',
    unassigned_only = 'unassigned_only',
    companyName = 'companyName',
    previousRiskScore = 'previousRiskScore',
    preselectTimeRange = 'preselectTimeRange',
    waitStatus = 'waitStatus',
}

export enum ReminderStatusType {
    INITIAL = 'INITIAL',
    REMINDER_1 = 'REMINDER_1',
    REMINDER_2 = 'REMINDER_2',
    WALLET_BLOCKED_DUE = 'WALLET_BLOCKED_DUE',
}

export enum SearchParamType {
    entityId = 'entityId',
    assigneeId = 'assigneeId',
    lastAssigneeId = 'lastAssigneeId',
    state = 'state',
    created_from = 'created_from',
    created_to = 'created_to',
    due_from = 'due_from',
    due_to = 'due_to',
    assigned_at_from = 'assigned_at_from',
    assigned_at_to = 'assigned_at_to',
    last_state_change_at_from = 'last_state_change_at_from',
    last_state_change_at_to = 'last_state_change_at_to',
    first_assigned_at_from = 'first_assigned_at_from',
    first_assigned_at_to = 'first_assigned_at_to',
    include_deleted = 'include_deleted',
    unassigned_only = 'unassigned_only',
    metadata = 'metadata',
    wb_due_threshold = 'wb_due_threshold',
    custom_order = 'custom_order',
    before = 'before',
    after = 'after',
    offset = 'offset',
    limit = 'limit',
    sorting_keys = 'sorting_keys',
    sorting_order = 'sorting_order',
    // of my own
    preselectTimeRange = 'preselectTimeRange',
}
