import { useParams } from 'react-router-dom'
import { creditBff } from '../bff'
import {
    Alert,
    Button,
    Collapse,
    Descriptions,
    Empty,
    Modal,
    notification,
    Row,
    Skeleton,
    Space,
} from 'antd'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { formatMoneyIntl } from 'utils/money'
import OverdraftApplicationStateTrail from '../components/underwriting/overdraft-application-state-trail'
import OverdraftApplicationStateTag from '../components/underwriting/overdraft-application-state-tag'
import { MouseEventHandler, useState } from 'react'
import type { UnderwritingApplicationFullDto } from 'bff/moons/generated/kale'
import ApplicationApprovalModal from '../components/underwriting/application-approval-modal'
import ApplicationInformationRequestModal from '../components/underwriting/application-information-request-modal'
import { getMinEligibilityApplicationLimit } from '../utils/getMinEligibilityApplicationLimit'
const { confirm } = Modal

export default function CreditUnderwriting() {
    const { id: companyId } = useParams()
    const [applicationToApprove, setApplicationToApprove] =
        useState<UnderwritingApplicationFullDto | null>(null)
    const [applicationForInformation, setApplicationForInformation] =
        useState<UnderwritingApplicationFullDto | null>(null)

    const { mutateAsync: approveApplication } =
        creditBff.approveEligibilityApplication.useMutation()
    const { mutateAsync: rejectApplication } = creditBff.rejectEligibilityApplication.useMutation()
    const { mutate: sendInformationRequest, isLoading: isSendingInfoRequest } =
        creditBff.sendInformationRequestForHigherLimit.useMutation({
            onSuccess: () => {
                setApplicationForInformation(null)
                notification.success({
                    message: 'Information request sent!',
                })
            },
            onError: () =>
                notification.error({
                    message: 'Information request failed!',
                }),
        })

    const { data, isLoading, isError } = creditBff.getUnderwriting.useQuery({ companyId })

    if (isLoading) {
        return <Skeleton active />
    }

    if (isError) {
        return <Alert message="There was an error fetching Underwriting Data." type="error" />
    }

    const handleRejectApplication = async (applicationId: string) => {
        try {
            await rejectApplication({ companyId, applicationId })
            notification.success({
                message: 'Application rejected!',
            })
        } catch {
            notification.error({
                message: 'Application rejection failed!',
            })
        }
    }

    const handleApprovalSubmit = async (values: { approvedLimit: number }) => {
        if (applicationToApprove === null) return null
        try {
            await approveApplication({
                companyId,
                applicationId: applicationToApprove.id,
                approvedLimit: values.approvedLimit,
            })
            notification.success({
                message: 'Application approved!',
            })
        } catch {
            notification.error({
                message: 'Application approval failed!',
            })
        } finally {
            setApplicationToApprove(null)
        }
    }

    const handleInformationRequestSubmit = async (values: { comment: string }) => {
        if (applicationForInformation === null) return null
        confirm({
            centered: true,
            title: 'Are you sure you want to send information request?',
            onOk() {
                sendInformationRequest({
                    companyId,
                    applicationId: applicationForInformation.id,
                    comment: values.comment,
                })
            },
        })
    }

    const showRejectionWarning = (applicationId: string) => {
        confirm({
            title: 'Warning! Limit has already been approved!',
            content: (
                <div>
                    <p>
                        After rejection, you need to manually adjust the customer's eligibility
                        limit to avoid them using the higher limit.
                    </p>
                    <p>
                        Approval email was already sent, so you should consider reaching out to the
                        customer to avoid confusion.
                    </p>
                </div>
            ),
            onOk() {
                handleRejectApplication(applicationId)
            },
        })
    }

    const handleStateChange: (
        action: 'approval' | 'rejection' | 'informationRequest',
        application: UnderwritingApplicationFullDto
    ) => MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement> =
        (action, application) => async (event) => {
            event.stopPropagation()
            switch (action) {
                case 'approval':
                    setApplicationToApprove(application)
                    break
                case 'rejection':
                    confirm({
                        centered: true,
                        title: 'Are you sure you want to reject this application?',
                        onOk() {
                            if (application.currentState === 'APPROVED') {
                                showRejectionWarning(application.id)
                            } else {
                                handleRejectApplication(application.id)
                            }
                        },
                    })
                    break
                case 'informationRequest':
                    setApplicationForInformation(application)
                    break
            }
        }

    const applicationItems = data.applications
        .map((application, index) => {
            return {
                testId: `application-header-${index}`,
                key: application.id ?? `${index}`,
                label: (
                    <Row align="middle" justify="space-between">
                        <StyledCardHeader>
                            <h3>
                                Application on {dayjs(application.createdAt).format('DD-MM-YYYY')}
                            </h3>
                            <OverdraftApplicationStateTag state={application.currentState} />
                        </StyledCardHeader>
                    </Row>
                ),
                children: (
                    <StyledApplicationContainer data-testid={`application-body-${index}`}>
                        <Descriptions column={3} bordered>
                            <Descriptions.Item label="Status">
                                <OverdraftApplicationStateTag state={application.currentState} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Requested limit">
                                {formatMoneyIntl(application.requestedLimit, {
                                    isMinorUnits: true,
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved limit">
                                {application.approvedLimit
                                    ? formatMoneyIntl(application.approvedLimit, {
                                          isMinorUnits: true,
                                      })
                                    : '-'}
                            </Descriptions.Item>
                        </Descriptions>
                        <OverdraftApplicationStateTrail
                            application={application}
                            companyId={companyId}
                        />
                    </StyledApplicationContainer>
                ),
                extra: (
                    <Space>
                        <Button
                            disabled={
                                application.currentState === 'APPROVED' ||
                                application.currentState === 'APPROVED_PENDING_ELIGIBILITY'
                            }
                            type="primary"
                            onClick={handleStateChange('approval', application)}
                        >
                            Approve
                        </Button>
                        <Button
                            disabled={application.currentState === 'REJECTED'}
                            danger
                            type="primary"
                            onClick={handleStateChange('rejection', application)}
                        >
                            Reject
                        </Button>
                        <Button
                            disabled={application.currentState !== 'PENDING'}
                            onClick={handleStateChange('informationRequest', application)}
                        >
                            Request information
                        </Button>
                    </Space>
                ),
            }
        })
        .reverse()

    return (
        <div>
            {applicationItems.length > 0 ? (
                <Collapse defaultActiveKey={applicationItems[0].key}>
                    {applicationItems.map((item) => (
                        <Collapse.Panel
                            key={item.key}
                            header={item.label}
                            extra={item.extra}
                            data-testid={item.testId}
                        >
                            {item.children}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {applicationToApprove && (
                <ApplicationApprovalModal
                    application={applicationToApprove}
                    minLimit={getMinEligibilityApplicationLimit(
                        applicationToApprove.requestedLimit.currency
                    )}
                    maxLimit={applicationToApprove.requestedLimit.value}
                    onCancel={() => setApplicationToApprove(null)}
                    onSubmit={handleApprovalSubmit}
                />
            )}
            <ApplicationInformationRequestModal
                application={applicationForInformation}
                onCancel={() => setApplicationForInformation(null)}
                onSubmit={handleInformationRequestSubmit}
                isLoading={isSendingInfoRequest}
            />
        </div>
    )
}

const StyledCardHeader = styled.div`
    display: flex;
    column-gap: 2rem;
    align-items: center;
    font-size: 1rem;
    font-weight: semibold;
`

const StyledApplicationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`
