import qs from 'qs'
import { useEffect, useState } from 'react'
import { PageOrder } from '@pleo-io/deimos'
import useSWR from 'swr'

import { dionysusRequest } from 'services/request'
import type { PaginatedResponseV2 } from 'types/cursor-pagination-v2'
import type {
    CreateDemoAccountRequest,
    DemoAccountModel,
    SortableDemoAccountProperty,
    DemoAccountResponse,
    EmployeeResponse,
    GetPrecreatedDemoAccountRequest,
    CompanyResponse,
    CompanyProfileResponse,
} from 'types/demo-accounts'
import { useCursorPaginationV2 } from 'utils/use-cursor-pagination-v2'
import type {
    CompanyProfile,
    DemoProfileListItem,
    CreateDemoAccountFromCompanyProfileRequest,
} from '@pleo-io/dionysus-ts-models'

const BASE_URL = 'rest/v1/demo-accounts'

export function getDionysus(route: string) {
    return dionysusRequest()
        .get(route)
        .then((res) => res.json())
}

export function postDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().post(route, {
        json: body,
    })
}

export function putDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().put(route, {
        json: body,
    })
}

export const createDemoAccount = async (
    createDemoAccountRequest: CreateDemoAccountRequest
): Promise<DemoAccountResponse> =>
    await postDionysus(BASE_URL, createDemoAccountRequest).then((res) => res.json())

export const getPreCreatedDemoAccount = async (
    createDemoAccountRequest: GetPrecreatedDemoAccountRequest
): Promise<DemoAccountResponse> =>
    await postDionysus(`${BASE_URL}/pre-created`, createDemoAccountRequest).then((res) =>
        res.json()
    )

export const createDemoProfile = async (
    createProfileRequest: CreateDemoAccountFromCompanyProfileRequest
): Promise<CompanyProfileResponse> =>
    await postDionysus(`rest/v1/company-profile/demo-account`, createProfileRequest).then((res) =>
        res.json()
    )

export const getAdmin = (employees: EmployeeResponse[]) =>
    employees.find(({ role }) => role.toLowerCase() === 'owner')

export const useCreateDemoAccount = () => {
    const [createDemoAccountRequest, setCreateDemoAccountRequest] =
        useState<CreateDemoAccountRequest | null>(null)
    const [createPreCreatedDemoAccountRequest, setCreatePreCreatedDemoAccountRequest] =
        useState<GetPrecreatedDemoAccountRequest | null>(null)
    const [accountDetails, setAccountDetails] = useState<EmployeeResponse | null>(null)
    const [companyDetails, setCompanyDetails] = useState<CompanyResponse | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        if (createDemoAccountRequest) {
            setLoading(true)
            createDemoAccount(createDemoAccountRequest)
                .then((res) => {
                    const adminDetails = getAdmin(res.employees)
                    if (adminDetails) {
                        setAccountDetails(adminDetails)
                    } else {
                        throw new Error('No admin')
                    }
                })
                .catch((err) => setError(err))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [createDemoAccountRequest])

    useEffect(() => {
        if (createPreCreatedDemoAccountRequest) {
            setLoading(true)
            getPreCreatedDemoAccount(createPreCreatedDemoAccountRequest)
                .then((res) => {
                    if (res.company) {
                        setCompanyDetails(res.company)
                    }
                    const adminDetails = getAdmin(res.employees)
                    if (adminDetails) {
                        setAccountDetails(adminDetails)
                    } else {
                        throw new Error('No admin')
                    }
                })
                .catch((err) => setError(err))
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [createPreCreatedDemoAccountRequest])

    const dismissError = () => setError(null)

    return {
        accountDetails,
        companyDetails,
        loading,
        error,
        setCreateDemoAccountRequest,
        setCreatePreCreatedDemoAccountRequest,
        dismissError,
    }
}

export const defaultLimit = 10
export const defaultSortedProperty: SortableDemoAccountProperty = 'CREATED_AT'
export const defaultPageOrder: PageOrder = PageOrder.DESC

export const useDemoAccounts = (requesterEmail?: string | null) => {
    const [demoAccounts, setDemoAccounts] = useState<DemoAccountModel[]>([])

    const { paginationRequest, setCurrentPagination, ...paginationMutations } =
        useCursorPaginationV2<SortableDemoAccountProperty>({
            sorting_keys: [defaultSortedProperty],
            sorting_order: [defaultPageOrder],
            limit: defaultLimit,
        })

    const response = useSWR<PaginatedResponseV2<DemoAccountModel, SortableDemoAccountProperty>>(
        requesterEmail
            ? `${BASE_URL}?${qs.stringify(
                  {
                      ...paginationRequest,
                      requesterEmail,
                  },
                  { arrayFormat: 'repeat' }
              )}`
            : null,
        getDionysus
    )

    const pagination = response.data?.pagination
    const data = response.data?.data

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
        }
    }, [pagination, setCurrentPagination])

    useEffect(() => {
        if (data) {
            setDemoAccounts(data)
        }
    }, [data])

    const updateExpiresAt = async (id: string, expiresAt: string | null) => {
        await putDionysus(`${BASE_URL}/expired/${id}`, { expiresAt })
        response.mutate()
    }

    return {
        ...response,
        ...paginationMutations,
        mutations: { updateExpiresAt },
        loading: !response.data && !response.error,
        demoAccounts,
        pagination,
    }
}

export const useDemoAccount = (demoAccountId: string | null) =>
    useSWR<DemoAccountResponse, Error>(
        demoAccountId ? `${BASE_URL}/${demoAccountId}` : null,
        getDionysus
    )

// Stubs methods API has been developed in https://linear.app/pleo/issue/TYR-227/find-best-place-for-hosting-profile-json
export const useGetDemoProfiles = () =>
    useSWR<DemoProfileListItem[], Error>(`rest/v1/demo-profiles`, getDionysus)

export const getDemoProfile = async (profileId: string): Promise<CompanyProfile> =>
    await getDionysus(`rest/v1/demo-profiles/${profileId}`)
