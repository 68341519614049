import type { ColumnsType } from 'antd/lib/table'
import type { Role } from 'types/demo-accounts'
import { Table, Tag, Typography } from 'antd'
import config from 'config'
import { type FC, type Key, useState } from 'react'
import { SingleDemoAccountEmployeesTableHeader } from './single-demo-account-employees-table-header'
import type { DemoAccount } from '../bff/index.bff'

const { Text } = Typography

const columns: ColumnsType<DemoAccount['employees'][number]> = [
    {
        title: 'Role',
        dataIndex: 'role',
        render: (role: Role) => <Tag color={role === 'OWNER' ? 'gold' : 'blue'}>{role}</Tag>,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: (email: string) => <Text copyable>{email}</Text>,
    },
    {
        title: 'Passcode',
        dataIndex: 'passcode',
        render: (passcode: string) => <Text copyable>{passcode}</Text>,
    },
    {
        title: 'SMS passcode',
        render: () => <Text copyable>{config.auth.smsOtp}</Text>,
    },
]

interface Props {
    demoAccount: DemoAccount
    revalidateDemoAccount?: () => void
}

export const SingleDemoAccountEmployeesTable: FC<Props> = ({
    revalidateDemoAccount,
    demoAccount,
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
    const [loadingRowKeys, setLoadingRowKeys] = useState<Key[]>([])

    const selectedEmployees = demoAccount.employees.filter((it) =>
        selectedRowKeys.includes(String(it.email))
    )

    const onChange = (rowKey: Key[], _selectedRows: DemoAccount['employees']) => {
        setSelectedRowKeys(rowKey)
    }

    return (
        <Table
            data-testid="single-demo-account-employees-table"
            title={() => (
                <SingleDemoAccountEmployeesTableHeader
                    demoAccount={demoAccount}
                    selectedEmployees={selectedEmployees}
                    onPreAction={() => setLoadingRowKeys(selectedRowKeys)}
                    onPostAction={() => {
                        setLoadingRowKeys([])
                        revalidateDemoAccount?.()
                    }}
                />
            )}
            loading={loadingRowKeys.length > 0}
            dataSource={demoAccount.employees}
            onRow={(record) => ({
                onClick: () => {
                    const email = String(record.email)

                    if (selectedRowKeys.includes(email)) {
                        setSelectedRowKeys(selectedRowKeys.filter((it) => it !== email))
                    } else {
                        setSelectedRowKeys(selectedRowKeys.concat([email]))
                    }
                },
            })}
            rowSelection={{
                selectedRowKeys,
                onChange,
            }}
            columns={columns}
            rowKey="email"
        />
    )
}
