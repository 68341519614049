// NOTE: This is not the same as CaseOutputState, as walletBlockedDue is not a valid state in CaseOutputState
export enum OddListTab {
    new = 'new',
    informationReceived = 'informationReceived',
    inProgress = 'inProgress',
    waitingForCustomer = 'waitingForCustomer',
    walletBlockedDue = 'walletBlockedDue',
    walletBlocked = 'walletBlocked',
    offboardingInitiated = 'offboardingInitiated',
    offboardingCompleted = 'offboardingCompleted',
    oddCompleted = 'oddCompleted',
}
