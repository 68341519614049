import { Alert } from 'antd'
import { useOddDetailContext } from './context-detail'

export const CaseLockedMessage = () => {
    const { isLocked } = useOddDetailContext()

    if (!isLocked) {
        return null
    }

    return (
        <Alert
            message="This case has been flagged as complete and can no longer be edited."
            type="warning"
            showIcon
        />
    )
}
