import type { ApplicationState } from 'bff/moons/generated/kale'
import dayjs from 'dayjs'

export const getOverdraftApplicationStatusColor = (status: ApplicationState | undefined) => {
    switch (status) {
        case 'APPROVED':
            return 'green'
        case 'PENDING':
        case 'PROCESSING':
            return 'yellow'
        case 'REJECTED':
        case 'ERROR':
            return 'red'
        case 'INFO_REQUIRED':
            return 'blue'
        default:
            return 'default'
    }
}

export function isInTwoMinutesWindow(date1: string, date2: string) {
    const d1 = dayjs(date1)
    const d2 = dayjs(date2)

    if (d1.isBefore(d2.subtract(1, 'minute')) || d1.isAfter(d2.add(1, 'minute'))) {
        return false
    } else {
        return true
    }
}

export const getHighApprovalLimit = (currency: string) => {
    switch (currency) {
        case 'EUR':
        case 'GBP':
            return 100_000
        case 'SEK':
            return 1_000_000
        case 'DKK':
            return 750_000
        default:
            return 0
    }
}
