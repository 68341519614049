import { Table, Button, Tooltip, message } from 'antd'
import { PageOrder } from '@pleo-io/deimos'
import type { SortOrder, ColumnType } from 'antd/lib/table/interface'
import dayjs from 'packages/dayjs'

import { defaultPageOrder, defaultSortedProperty } from 'services/dionysus/demo-accounts'
import type { CursorRequestParamsV2 } from 'types/cursor-pagination-v2'
import type { DemoAccountModel, SortableDemoAccountProperty } from 'types/demo-accounts'
import { isPast } from 'utils/dayjs'
import { ActionsContainer } from 'components/layout-containers'
import { DayjsDatePicker } from 'packages/form/date-picker'

const renderFormattedTime = (timeStamp?: string) =>
    timeStamp ? dayjs(timeStamp).format('lll') : '-'

const sortOrderToPageOrder = (sortOrder?: SortOrder): PageOrder => {
    switch (sortOrder) {
        case 'ascend':
            return PageOrder.ASC
        case 'descend':
            return PageOrder.DESC
        default:
            return defaultPageOrder
    }
}

const fieldToSortableProperty = (field?: string): SortableDemoAccountProperty => {
    switch (field) {
        case 'createdAt':
            return 'CREATED_AT'
        case 'updatedAt':
            return 'UPDATED_AT'
        case 'expiresAt':
            return 'EXPIRES_AT'
        default:
            return defaultSortedProperty
    }
}

interface Props {
    demoAccounts: DemoAccountModel[]
    loading: boolean
    updateSorting: (
        sorting: Pick<
            CursorRequestParamsV2<SortableDemoAccountProperty>,
            'sorting_keys' | 'sorting_order'
        >
    ) => void
    updateExpiresAt: (demoAccountId: string, expiresAt: string | null) => Promise<void>
    onViewCompany: (demoAccountId: string) => void
}

const ListDemoAccounts = ({
    onViewCompany,
    demoAccounts,
    loading,
    updateSorting,
    updateExpiresAt,
}: Props) => {
    const columns: ColumnType<DemoAccountModel>[] = [
        {
            title: 'Account name',
            dataIndex: 'name',
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
            render: renderFormattedTime,
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            sorter: (a, b) => dayjs(a.updatedAt).valueOf() - dayjs(b.updatedAt).valueOf(),
            render: renderFormattedTime,
        },
        {
            title: 'Expires at',
            dataIndex: 'expiresAt',
            sorter: (a, b) => dayjs(a.expiresAt).valueOf() - dayjs(b.expiresAt).valueOf(),
            render: (expiresAt: string | undefined, { id }) => {
                const value = expiresAt ? dayjs(expiresAt) : undefined

                return (
                    <Tooltip title="Edit expiry">
                        <DayjsDatePicker
                            defaultValue={value}
                            disabledDate={isPast}
                            allowClear
                            onChange={async (date) => {
                                try {
                                    await updateExpiresAt(id, date?.toISOString() ?? null)
                                    message.success(
                                        `${date === null ? 'Removed' : 'Updated'} expires at`
                                    )
                                } catch (e) {
                                    message.error((e as Error).message)
                                }
                            }}
                        />
                    </Tooltip>
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (demoAccountId: string) => (
                <ActionsContainer>
                    <Button type="link" onClick={() => onViewCompany(demoAccountId)}>
                        View details
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    return (
        <Table<DemoAccountModel>
            loading={loading}
            dataSource={demoAccounts}
            rowKey="id"
            columns={columns}
            pagination={false}
            onChange={(_pagination, _filters, sorter, _extra) => {
                // sorter is union of SorterResult<DemoAccountModel> | SorterResult<DemoAccountModel>[]
                // we only want to handle the case where it isn't an array
                if ('order' in sorter) {
                    updateSorting({
                        sorting_keys: [fieldToSortableProperty(sorter.field as string)],
                        sorting_order: [sortOrderToPageOrder(sorter.order)],
                    })
                }
            }}
        />
    )
}

export default ListDemoAccounts
