import { Button, List, Space, notification } from 'antd'
import dayjs from 'dayjs'
import { ExportOutlined } from '@ant-design/icons'
import type { DocumentFullDto } from 'bff/moons/generated/kale'
import { creditBff } from '../../bff'

interface Props {
    title: string
    documents: DocumentFullDto[]
    companyId: string
}

export default function DocumentsList({ documents, companyId, title }: Props) {
    const { mutateAsync: fetchDocument } = creditBff.fetchApplicationDocument.useMutation()

    const handleDocumentDownload = (documentId: string) => async () => {
        try {
            const applicationDocument = await fetchDocument({ companyId, documentId })

            const response = await fetch(applicationDocument.s3Url)
            if (!response.ok) {
                throw new Error('Failed to download document')
            }
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (err) {
            notification.error({
                message: 'Document download failed!',
            })
        }
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <List
                size="large"
                header={<strong>{title}</strong>}
                bordered
                itemLayout="horizontal"
                dataSource={documents}
                renderItem={(document) => (
                    <List.Item
                        actions={[
                            <Button
                                key={document.id}
                                type="link"
                                onClick={handleDocumentDownload(document.id)}
                                icon={<ExportOutlined />}
                            >
                                View
                            </Button>,
                        ]}
                    >
                        <List.Item.Meta
                            title={document.fileName}
                            description={`Uploaded at: ${dayjs(document.createdAt).format('DD-MM-YYYY HH:mm')}`}
                        />
                    </List.Item>
                )}
            />
        </Space>
    )
}
