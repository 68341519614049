import { Card } from 'antd'
import type { FC } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { getEmojiFlag } from 'countries-list'
import { DemoAccountExpiryInput } from './components/demo-account-expiry-input'
import { DemoAccountSubscriptionDropdown } from './components/demo-account-subscription-dropdown'
import { DemoAccountCreateExpenseDropdown } from './components/demo-account-create-expense-dropdown'
import { DemoAccountQuickLinksDropdown } from './components/demo-account-quick-links-dropdown'
import { DemoAccountMiscellaneousActionsDropdown } from './components/demo-account-miscellaneous-actions-dropdown'
import type { DemoAccount } from '../bff/index.bff'

interface Props {
    demoAccount: DemoAccount
    revalidateDemoAccount?: () => void
}

export const SingleDemoAccountCompanyActionsCard: FC<Props> = ({
    demoAccount,
    revalidateDemoAccount,
}: Props) => {
    const emojiFlag = getEmojiFlag(demoAccount.company.country)

    return (
        <>
            <Card
                data-testid="single-demo-account-company-actions-card"
                title={`${emojiFlag} ${demoAccount.company.name}`}
                style={{ marginBottom: spacing.space16 }}
                extra={<DemoAccountQuickLinksDropdown demoAccount={demoAccount} />}
            >
                <RowSpaceBetween>
                    <Row>
                        <Column>
                            <DemoAccountCreateExpenseDropdown
                                demoAccount={demoAccount}
                                revalidateDemoAccount={revalidateDemoAccount}
                            />
                        </Column>
                        <Column>
                            <DemoAccountSubscriptionDropdown
                                demoAccount={demoAccount}
                                revalidateDemoAccount={revalidateDemoAccount}
                            />
                        </Column>
                        <Column>
                            <DemoAccountMiscellaneousActionsDropdown demoAccount={demoAccount} />
                        </Column>
                    </Row>
                    <Column>
                        <DemoAccountExpiryInput
                            demoAccount={demoAccount}
                            revalidateDemoAccount={revalidateDemoAccount}
                        />
                    </Column>
                </RowSpaceBetween>
            </Card>
        </>
    )
}

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${spacing.space8};
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space8};
`

const RowSpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
`
