import { message, Tooltip } from 'antd'
import { DayjsDatePicker } from 'packages/form/date-picker'
import dayjs from 'packages/dayjs'
import { isPast } from 'utils/dayjs'
import { spacing } from 'theme/tokens'
import styled from 'styled-components'
import { demoAccountsBff } from '../../bff/demo-accounts-bff'
import type { DemoAccount } from '../../bff/index.bff'

interface Props {
    demoAccount: DemoAccount
    revalidateDemoAccount?: () => void
}

export const DemoAccountExpiryInput = ({ demoAccount, revalidateDemoAccount }: Props) => {
    const { mutateAsync: updateExpiry, isLoading } =
        demoAccountsBff.singleAccount.updateExpiry.useMutation()

    return (
        <FlexRow>
            <p>Expires at</p>
            <Tooltip title="Edit expiry">
                <DayjsDatePicker
                    disabled={isLoading}
                    defaultValue={demoAccount.expiresAt ? dayjs(demoAccount.expiresAt) : undefined}
                    disabledDate={isPast}
                    allowClear
                    onChange={async (date) => {
                        const result = await updateExpiry({
                            demoAccountId: demoAccount.id,
                            dateIsoString: date?.toISOString(),
                        })

                        revalidateDemoAccount?.()

                        if (result.success) {
                            message.success(`${date === null ? 'Removed' : 'Updated'} expires at`)
                        } else {
                            message.error(result.error)
                        }
                    }}
                />
            </Tooltip>
        </FlexRow>
    )
}

const FlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: ${spacing.space8};
`
