import { Alert, Button, Space } from 'antd'

import { useOddDetailContext } from './context-detail'
import { bff } from './bff-hooks'

export const WalletBlockedWarning = () => {
    const { companyId, caseId } = useOddDetailContext()
    const { data, isLoading } = bff.walletBlockedWarning.getWalletBlockedStatus.useQuery({
        caseId,
        companyId,
    })
    const { mutateAsync: updateCaseStatusToWalletBlocked, isLoading: isUpdatingCaseStatus } =
        bff.walletBlockedWarning.updateCaseStatusToWalletBlocked.useMutation()

    const handleClick = () => {
        updateCaseStatusToWalletBlocked({ caseId })
    }

    if (!data?.showWarning || isLoading) {
        return null
    }

    return (
        <Alert
            message="Wallet blocked"
            description={
                <Space direction="vertical">
                    <p>
                        It looks like this company's wallet has been blocked outside of the ODD
                        process. Please click below to update the case status and proceed with
                        offboarding.
                    </p>
                    <Button onClick={handleClick} loading={isUpdatingCaseStatus}>
                        Update case status
                    </Button>
                </Space>
            }
            type="warning"
            showIcon
        />
    )
}
