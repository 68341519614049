import { createContext, ReactNode, useContext, useState } from 'react'

interface ModalMetadata {
    isOpen: boolean
    open: (caseId: string) => void
    close: () => void
    caseId: string
}

interface OddDetailContext {
    companyOffboardedModal: ModalMetadata
    companyOffboardedInitiatedModal: ModalMetadata
}

const OddListContext = createContext<OddDetailContext | undefined>(undefined)

export const useOddListContext = () => {
    const context = useContext(OddListContext)
    if (!context) {
        throw new Error('OddListContext must be used within a OddListContextProvider')
    }
    return context
}

export const OddListProvider = ({ children }: { children: ReactNode }) => {
    const [isCompanyOffboardedModalOpen, setIsCompanyOffboardedModalOpen] = useState(false)
    const [isCompanyOffboardedInitiatedModalOpen, setIsCompanyOffboardedInitiatedModalOpen] =
        useState(false)
    const [companyCaseId, setCompanyCaseId] = useState<string>('')

    const companyOffboardedModal = {
        isOpen: isCompanyOffboardedModalOpen,
        open: (caseId: string) => {
            setIsCompanyOffboardedModalOpen(true)
            setCompanyCaseId(caseId)
        },
        close: () => {
            setIsCompanyOffboardedModalOpen(false)
        },
        caseId: companyCaseId,
    }

    const companyOffboardedInitiatedModal = {
        isOpen: isCompanyOffboardedInitiatedModalOpen,
        open: (caseId: string) => {
            setIsCompanyOffboardedInitiatedModalOpen(true)
            setCompanyCaseId(caseId)
        },
        close: () => {
            setIsCompanyOffboardedInitiatedModalOpen(false)
        },
        caseId: companyCaseId,
    }

    return (
        <OddListContext.Provider
            value={{
                companyOffboardedModal,
                companyOffboardedInitiatedModal,
            }}
        >
            {children}
        </OddListContext.Provider>
    )
}
