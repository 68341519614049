import { Button, Card, Typography } from 'antd'
import dayjs from 'dayjs'
import { useOddDetailContext } from './context-detail'
import { bff } from './bff-hooks'

const { Text } = Typography

const detectUrl = (notes: string) => {
    if (notes && notes.includes('https')) {
        if (notes.split(' ').length === 1) {
            return <a href={notes}>{notes}</a>
        } else {
            const wordArray = notes.split(' ')

            const finalComponent: any = []

            wordArray.map((word: string, index: number) => {
                if (word.includes('https')) {
                    finalComponent.push(
                        <>
                            <a key={index} href={word} rel="noreferrer noopener" target="_blank">
                                {word}
                            </a>{' '}
                        </>
                    )
                } else {
                    finalComponent.push(
                        <>
                            <Text key={index}>{word} </Text>{' '}
                        </>
                    )
                }
            })

            return <div>{finalComponent}</div>
        }
    } else return <Text>{notes}</Text>
}

const Notes: React.FC = () => {
    const { caseId, notesModal, isNotesDisabled } = useOddDetailContext()
    const { data: notes } = bff.notes.getNotes.useQuery({ caseId })
    const { data: notesTimeline } = bff.notes.getNotesTimeline.useQuery({ caseId })
    return (
        <Card
            title="Notes"
            style={{ position: 'sticky', top: 16 }}
            styles={{ body: { padding: '8px 24px' } }}
            actions={[
                <Button onClick={notesModal.open} key="add-edit-notes" disabled={isNotesDisabled}>
                    {!notes ? 'Add' : 'Edit'} Notes
                </Button>,
            ]}
        >
            {notes && <div>{detectUrl(notes)}</div>}
            {!notes && (
                <blockquote style={{ fontSize: 12, color: 'darkgrey' }}>
                    No notes on case yet
                </blockquote>
            )}
            {notesTimeline?.length && notesTimeline[0] ? (
                <div key={notesTimeline[0].id}>
                    <blockquote style={{ fontSize: 12, color: 'darkgrey' }}>
                        {`${notesTimeline[0].by.firstName} ${notesTimeline[0].by.lastName}, ${dayjs(notesTimeline[0].by.at).format('lll')}`}
                    </blockquote>
                </div>
            ) : null}
        </Card>
    )
}

export default Notes
