import { CheckCircleOutlined, ExclamationCircleOutlined, PushpinOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import type { ColumnProps } from 'antd/lib/table'

import type { CheckResultResponse } from 'bff/moons/generated/onboarding-validator'
import { formatDate } from 'packages/dates/dates'
import { PINNED_CHECK_IDS, sortCheckResults } from '../../utils'

interface Props {
    checkResults: CheckResultResponse[]
    loading: boolean
}

const StpCheckList = ({ checkResults, loading }: Props) => {
    const columns: ColumnProps<CheckResultResponse>[] = [
        {
            dataIndex: 'status',
            key: 'status',
            width: '40px',
            align: 'center',
            render: (status) => <StpCheckLabel isError={status === 'FAILED'} />,
        },
        {
            title: 'Check ID',
            dataIndex: 'checkId',
            ellipsis: true,
            render: (checkId) => (
                <>
                    {PINNED_CHECK_IDS.includes(checkId) && (
                        <PushpinOutlined style={{ marginRight: '0.5rem' }} />
                    )}
                    {checkId}
                </>
            ),
        },
        {
            title: 'Check Name',
            dataIndex: 'checkName',
            render: (checkName) => checkName,
        },
        {
            title: 'Check Description',
            dataIndex: 'checkDescription',
            render: (checkDescription) => checkDescription,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => StpCheckStatus({ status }),
        },
        {
            title: 'Evidence',
            dataIndex: 'evidence',
            width: '35%',
            render: (evidence) => evidence,
        },
        {
            title: 'Processed At',
            dataIndex: 'performedAt',
            render: (performedAt) => formatDate(performedAt),
        },
    ]

    return (
        <>
            <Table<CheckResultResponse>
                loading={loading}
                dataSource={sortCheckResults(checkResults)}
                rowKey="id"
                columns={columns}
                pagination={false}
                data-testid="stp-check-list"
            />
        </>
    )
}

const StpCheckStatus = ({ status }: { status: string }) => {
    switch (status) {
        case 'PASSED':
            return <Tag color="green">{status}</Tag>
        case 'FAILED':
            return <Tag color="red">{status}</Tag>
    }
}

const StpCheckLabel = ({ isError }: { isError?: boolean }) => {
    return isError ? (
        <ExclamationCircleOutlined style={{ color: '#fb6060' }} />
    ) : (
        <CheckCircleOutlined style={{ color: '#52c41a' }} />
    )
}

export default StpCheckList
