import { Button, Row, Space } from 'antd'
import { OverdraftCard } from './overdraft-card'
import { PleoReserveCard } from './pleo-reserve-card'
import { DirectDebitCard } from './direct-debit-card'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions } from 'store/modules/employees/slice'
import { useNavigate } from 'react-router-dom'

const CreditTab = ({ companyId }: { companyId: string }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(actions.fetchCompanyEmployees({ deimosId: companyId, includeDeleted: true }))
    }, [companyId, dispatch])

    const handleNewCreditNav = () => {
        navigate(`/customer-success/credit/${companyId}/overview`)
    }

    return (
        <Space direction="vertical" size="middle">
            <Row justify={'start'}>
                <Button type="default" onClick={handleNewCreditNav}>
                    Go to new credit
                </Button>
            </Row>
            <PleoReserveCard companyId={companyId} />
            <OverdraftCard companyId={companyId} />
            <DirectDebitCard companyId={companyId} />
        </Space>
    )
}

export default CreditTab
