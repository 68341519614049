import type { FC } from 'react'
import { Button, Dropdown, MenuProps, message } from 'antd'
import { ControlOutlined } from '@ant-design/icons'
import { demoAccountsBff } from '../../bff/demo-accounts-bff'
import type { DemoAccount } from '../../bff/index.bff'

interface Props {
    demoAccount: DemoAccount
    revalidateDemoAccount?: () => void
}

const planKeys = ['Beyond', 'Advanced', 'Essential', 'Starter'] as const

export const DemoAccountSubscriptionDropdown: FC<Props> = ({
    demoAccount,
    revalidateDemoAccount,
}) => {
    const { mutateAsync: changeSubscription, isLoading } =
        demoAccountsBff.singleAccount.changeSubscription.useMutation()

    const items: MenuProps['items'] = planKeys.map((plan) => ({
        key: plan,
        label: plan,
        onClick: async () => {
            const result = await changeSubscription({
                companyId: demoAccount.company.id,
                plan,
            })

            if (result.success) {
                message.success(`Changed subscription to ${plan}`)
            } else {
                message.error('Failed to change subscription: ' + result.error)
            }

            revalidateDemoAccount?.()
        },
    }))

    return (
        <Dropdown menu={{ items }} trigger={['hover']} disabled={isLoading}>
            <Button loading={isLoading} disabled={isLoading} icon={<ControlOutlined />}>
                Change subscription
            </Button>
        </Dropdown>
    )
}
