import { useState, useEffect } from 'react'

import type { CursorRequestParamsV2, PaginationResponseV2 } from 'types/cursor-pagination-v2'
import { usePrevious } from './use-previous'

export function useCursorPaginationV2<T extends string>(
    defaultPagination: CursorRequestParamsV2<T>
) {
    const [paginationRequest, setPaginationRequest] =
        useState<CursorRequestParamsV2<T>>(defaultPagination)

    const [currentPagination, setCurrentPagination] = useState<PaginationResponseV2<T>>()
    const [firstPageCursor, setFirstPageCursor] = useState<string | null>()
    const previousPagination = usePrevious(currentPagination)

    useEffect(() => {
        if (!previousPagination && currentPagination) {
            setFirstPageCursor(currentPagination.startCursor)
        }
    }, [previousPagination, currentPagination])

    const nextPage = () => {
        if (currentPagination?.hasNextPage) {
            setPaginationRequest((previousState) => ({
                ...previousState,
                before: undefined,
                after: currentPagination.endCursor!,
            }))
        }
    }

    const previousPage = () => {
        if (currentPagination?.hasPreviousPage) {
            setPaginationRequest((previousState) => ({
                ...previousState,
                after: undefined,
                before: currentPagination.startCursor!,
            }))
        }
    }

    const updateSorting = (
        sorting: Pick<CursorRequestParamsV2<T>, 'sorting_keys' | 'sorting_order'>
    ) => {
        setPaginationRequest((prev) => ({
            ...prev,
            sorting_keys: sorting.sorting_keys,
            sorting_order: sorting.sorting_order,
        }))
    }

    const updateLimit = (limit: number) => {
        setPaginationRequest((prev) => ({
            ...prev,
            limit,
        }))
    }

    const firstPage = () => {
        if (firstPageCursor) {
            setPaginationRequest((previousState) => ({
                ...previousState,
                before: undefined,
                after: undefined,
            }))
        }
    }

    const resetFirstPage = () => {
        setPaginationRequest((previousState) => ({
            ...defaultPagination,
            limit: previousState.limit,
            sorting_keys: previousState.sorting_keys,
            sorting_order: previousState.sorting_order,
        }))
    }

    return {
        paginationRequest,
        updateLimit,
        updateSorting,
        previousPage,
        nextPage,
        firstPage,
        setCurrentPagination,
        resetFirstPage,
    }
}
