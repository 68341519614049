import { message } from 'antd'
import { useState } from 'react'
import { assignPartnerBookkeeper, unassignPartnerBookkeeper } from 'services/kerberos/partners'
import type { PartnerEmployee } from 'components/index.bff'
import type { PartnerCompany } from '../index.bff'
import { PartnerRole } from 'types/employee'
import { getPartnerEmployeeName } from 'utils/partner-employees'
import { bff as partnerBff } from '../bff'
import { bff } from 'components/bff-hooks'

export const getBookkeeperLabel = (bookkeeper: PartnerEmployee) => {
    const name = getPartnerEmployeeName(bookkeeper)
    const isAdmin = bookkeeper.role === PartnerRole.OWNER ? ' (Admin)' : ''
    return name + isAdmin
}

export const useAssignBookkeeper = (
    partnerId: string,
    unassignedBookkeepers: PartnerEmployee[],
    company: PartnerCompany
) => {
    const [userId, setUserId] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const bookkeepers: { [userId: string]: PartnerEmployee } = unassignedBookkeepers.reduce(
        (prev, curr) => (curr.userId ? { ...prev, [curr.userId]: curr } : prev),
        {}
    )

    const { refetch: refetchEmployees } =
        bff.components.partnerEmployees.getPartnerEmployees.useQuery({ partnerId })
    const { refetch: refetchClients } = partnerBff.partnerClients.getPartnerClients.useQuery({
        partnerId,
    })

    const options = unassignedBookkeepers.map((bookkeeper) => ({
        key: bookkeeper.userId,
        label: getBookkeeperLabel(bookkeeper),
        value: bookkeeper.userId,
    }))

    const assignBookkeeper = async () => {
        try {
            setLoading(true)
            if (!userId) {
                return
            }

            await assignPartnerBookkeeper(partnerId, userId, company.id)
            message.success(
                `${getPartnerEmployeeName(bookkeepers[userId])} is now assigned to ${company.name}`
            )
            setUserId('')
            refetchEmployees()
            refetchClients()
        } catch (error) {
            message.error('Something went wrong! Contact Partner Experience Team', 8)
        } finally {
            setLoading(false)
        }
    }
    return {
        loading,
        options,
        userId,
        assignBookkeeper,
        setUserId,
    }
}

export const useUnassignBookkeeper = (bookkeeper: PartnerEmployee, company: PartnerCompany) => {
    const [loading, setLoading] = useState(false)
    const { refetch: refetchEmployees } =
        bff.components.partnerEmployees.getPartnerEmployees.useQuery({
            partnerId: bookkeeper.partnerId,
        })
    const { refetch: refetchClients } = partnerBff.partnerClients.getPartnerClients.useQuery({
        partnerId: bookkeeper.partnerId,
    })

    const unassignBookkeeper = async () => {
        try {
            setLoading(true)
            const { partnerId, userId } = bookkeeper
            if (!userId) {
                return
            }
            await unassignPartnerBookkeeper(partnerId, userId, company.id)
            message.success(`${getPartnerEmployeeName(bookkeeper)} unassiged from ${company.name}`)
            refetchEmployees()
            refetchClients()
        } catch (error) {
            message.error('Something went wrong! Please contact Partner Experience Team', 8)
        } finally {
            setLoading(false)
        }
    }
    return { loading, unassignBookkeeper }
}
