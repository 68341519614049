import { useCompanyEntitlements } from './hooks/use-company-entitlements'
import { CompanyEntitlementsTable } from './components/company-entitlements-table'
import { ContentContainer } from '../../../../components/layout-containers'
import { useSelector } from 'react-redux'
import { selectCompanyId } from '../../../../store/modules/support/company/selectors'

export const CompanyEntitlements = () => {
    const companyId = useSelector(selectCompanyId)
    const { companyEntitlementsTableInfo: tableInfo, isLoading: isLoading } =
        useCompanyEntitlements(companyId)
    return (
        <ContentContainer>
            <CompanyEntitlementsTable data={tableInfo} isLoading={isLoading} />
        </ContentContainer>
    )
}
