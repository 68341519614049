export const OAUTH_SCOPES = [
    'export-items:read',
    'export-jobs:read',
    'export-jobs:write',
    'tag-groups:read',
    'tag-groups:write',
    'tax-codes:read',
    'tax-codes:write',
    'users:read',
    'users:write',
    'users:delete',
    'vendors:read',
    'vendors:write',
    'vendors:delete',
]

export const DEFAULT_TABLE_ROW_COUNT = 20
