import useSWR from 'swr'
import { fetcher } from './fetcher'

import type { Address } from 'types/deimos-company'

import { useState } from 'react'
import { useDebounce } from 'utils/use-debounce'

import type { CompanyPartner } from 'types/partner-portal'
import type { PartnerEmployee } from 'types/employee'
import { calypsoRequest } from 'services/request'

export interface Timestamps {
    createdAt: string
    updatedAt: string | null
    deletedAt: string | null
}

export interface Partner extends Timestamps {
    id: string
    ownCompanyId: string | null
    registrationNumber: string
    name: string
    status: Record<string, any>
    tradingName?: string
    address: Address
    type?: string
}

export async function invitePartnerEmployee(partnerId: string, firstName = '', email: string) {
    return await calypsoRequest()
        .post(`rest/v1/partners/${partnerId}/partner-employees/invite`, {
            json: { firstName, email, role: 'member' },
        })
        .then((response) => response.json())
}

export const useGetPartner = (partnerId?: string | null) => {
    const response = useSWR<Partner, Error>(
        partnerId ? `rest/v1/partners/${partnerId}` : null,
        fetcher,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )

    const { data, error, mutate } = response

    const editPartner = async (body: Partial<Partner>) => {
        if (!data || !partnerId) {
            return
        }
        await calypsoRequest().put(`rest/v1/partners/${partnerId}`, { json: body })
        mutate()
    }

    return { data, error, mutations: { editPartner } }
}

export const useSearchPartners = () => {
    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 500)
    const { data: partners } = useSWR<Partner[]>(
        `rest/v1/partners${debouncedQuery ? '?query=' + debouncedQuery : ''}`,
        fetcher,
        { revalidateOnFocus: false }
    )

    return { setQuery, partners }
}

export const useGetPartnerByOwnCompanyId = (ownCompanyId: string) => {
    return useSWR<Partner, Error>(
        ownCompanyId ? `rest/v1/partners?ownCompanyId=${ownCompanyId}` : '',
        async (url: string) => {
            const result = await fetcher(url)
            if (result.length === 1) {
                return result[0]
            }
            return null
        },
        {
            shouldRetryOnError: false,
        }
    )
}

export const useGetCompanyPartners = (companyId?: string | null) =>
    useSWR<CompanyPartner[], Error>(
        companyId ? `rest/v1/companies/${companyId}/partners` : '',
        fetcher,
        {
            shouldRetryOnError: false,
        }
    )

export const useGetDeactivatedPartnerEmployees = (partnerId: string) =>
    useSWR<PartnerEmployee[], Error>(
        partnerId ? `rest/v1/partners/${partnerId}/partner-employees?deleted=true` : '',
        fetcher,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )

export const useGetPartnerEmployee = (partnerId?: string, partnerEmployeeId?: string) =>
    useSWR<PartnerEmployee, Error>(
        partnerId && partnerEmployeeId
            ? `rest/v1/partners/${partnerId}/partner-employees/${partnerEmployeeId}`
            : '',
        fetcher,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )
