import { useEffect, useMemo, useState, type FC } from 'react'
import { Table, Typography, Button, Modal } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import ReactJson from 'react-json-view'
import dayjs from 'packages/dayjs'

import { ActionsContainer } from 'components/layout-containers'
import ErrorState from 'components/error-state/error-state'

import { bff } from '../bff'
import type {
    CursorPaginatedResponseEventLogModel,
    EventLogModel,
    EventLogModelDimensions,
    EventTypeModel,
} from 'bff/moons/generated/ganymede'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import CursorPaginationControls from 'components/cursor-pagination-controls'

const { Text } = Typography
const DEFAULT_ROW_COUNT = 10

interface Props {
    eventLogResponse?: CursorPaginatedResponseEventLogModel
    fetch: () => void
    isLoading: boolean
    error: boolean
}

const EventAuditLogs: FC<React.PropsWithChildren<Props>> = ({
    eventLogResponse,
    fetch,
    isLoading,
    error,
}) => {
    const eventAuditLogs = eventLogResponse?.data

    const columns: ColumnProps<EventLogModel>[] = [
        {
            title: 'Date',
            dataIndex: 'occurredAt',
            render: (occurredAt) => <Text>{dayjs(occurredAt).format('ll')}</Text>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type: EventTypeModel) => type.eventType ?? '',
        },
        {
            title: 'Sub type',
            dataIndex: 'type',
            render: (type: EventTypeModel) => type.eventSubType ?? '',
        },
        {
            title: 'Description',
            dataIndex: 'dimensions',
            render: (dimensions: EventLogModelDimensions) => dimensions['description'] ?? '',
        },
        {
            title: 'System',
            dataIndex: 'sourceUserSystem',
        },
        {
            title: 'User',
            dataIndex: 'sourceUserId',
        },
        {
            title: 'Actions',
            render: (_value: any, record: EventLogModel) => (
                <ActionsContainer>
                    <Button
                        type="link"
                        onClick={() => {
                            Modal.confirm({
                                centered: true,
                                title: 'Event Audit Complete Data',
                                width: '600px',
                                content: (
                                    <ReactJson src={record} name={false} displayDataTypes={false} />
                                ),
                            })
                        }}
                    >
                        More information
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    if (error) {
        return <ErrorState onRetry={fetch} />
    }

    return (
        <Table
            loading={isLoading}
            rowKey="id"
            dataSource={eventAuditLogs}
            columns={columns}
            pagination={false}
        />
    )
}

const EventAuditLogsContainer: FC<
    React.PropsWithChildren<{ companyId: string; feature: string }>
> = ({ companyId, feature }) => {
    const [eventAuditLogs, setEventAuditLogs] = useState<EventLogModel[]>([])

    const {
        paginationRequest,
        setCurrentPagination,
        resetFirstPage,
        updateLimit: currentPaginationUpdateLimit,
        ...paginationMutations
    } = useCursorPagination({ limit: DEFAULT_ROW_COUNT, sorting: [] })

    const updateLimit = (updatedLimit: number) => {
        currentPaginationUpdateLimit(updatedLimit)
        resetFirstPage()
    }

    const { data, error, refetch, isLoading } = bff.getAuditLogs.useQuery({
        companyId: companyId,
        feature: feature,
        from: undefined,
        to: undefined,
        limit: paginationRequest.limit,
        before: paginationRequest.before,
        after: paginationRequest.after,
    })

    const pagination = useMemo(
        () =>
            data?.pagination
                ? {
                      ...data?.pagination,
                      startCursor: data?.pagination.startCursor
                          ? data?.pagination.startCursor
                          : null,
                      endCursor: data?.pagination.endCursor ? data?.pagination.endCursor : null,
                  }
                : undefined,
        [data?.pagination]
    )

    const response = data?.data

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
        }
    }, [pagination, setCurrentPagination, paginationRequest.limit])

    useEffect(() => {
        if (response) {
            setEventAuditLogs(response)
        }
    }, [response])

    return (
        <>
            <EventAuditLogs
                eventLogResponse={data}
                fetch={refetch}
                error={!!error}
                isLoading={isLoading}
            />
            {pagination && (
                <CursorPaginationControls
                    isFetching={!eventAuditLogs}
                    pagination={pagination}
                    onChangeLimit={updateLimit}
                    limit={paginationRequest.limit}
                    onNextPage={paginationMutations.nextPage}
                    onPrevPage={paginationMutations.previousPage}
                    onFirstPage={resetFirstPage}
                />
            )}
        </>
    )
}

export default EventAuditLogsContainer
