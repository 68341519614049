import type { FC } from 'react'

import { Typography } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import type { DemoAccount } from '../bff/index.bff'
import { DemoAccountEmployeeActionsDropdown } from './components/demo-account-employee-actions-dropdown'

const { Text } = Typography

interface Props {
    selectedEmployees: DemoAccount['employees']
    demoAccount: DemoAccount
    onPreAction?: () => void
    onPostAction?: () => void
}

export const SingleDemoAccountEmployeesTableHeader: FC<Props> = ({
    selectedEmployees,
    demoAccount,
    onPreAction,
    onPostAction,
}) => {
    return (
        <FlexContainer>
            <Text strong>Employees</Text>
            <FlexRow>
                <DemoAccountEmployeeActionsDropdown
                    demoAccount={demoAccount}
                    selectedEmployees={selectedEmployees}
                    onPreAction={onPreAction}
                    onPostAction={onPostAction}
                />
            </FlexRow>
        </FlexContainer>
    )
}

const FlexContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${spacing.space8};
`
