import type { FC } from 'react'
import { Button, Dropdown, MenuProps } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import config from 'config'
import type { DemoAccount } from '../../bff/index.bff'

interface Props {
    demoAccount: DemoAccount
    revalidateDemoAccount?: () => void
}

interface Link {
    label: string
    link: string
}

const isLink = (it: Link | undefined): it is Link => Boolean(it?.link && it?.label)

const getLinks = (demoAccount: DemoAccount) =>
    [
        {
            label: 'Compliance backoffice',
            link: `/compliance/companies/${demoAccount.company.id}`,
        },
        {
            label: 'Company 360',
            link: `/customer-success/company-360/${demoAccount.company.id}`,
        },
        {
            label: 'Pleo Web login',
            link: `${config.demoWebApp.url}/login`,
        },
        config.testFlight?.appUrl
            ? {
                  label: 'Pleo Mobile App login',
                  link: config.testFlight.appUrl,
              }
            : undefined,
    ].filter(isLink)

export const DemoAccountQuickLinksDropdown: FC<Props> = ({ demoAccount }) => {
    const items: MenuProps['items'] = getLinks(demoAccount).map(({ label, link }) => ({
        key: label,
        label,
        onClick: () => {
            window.open(link, '_blank')
        },
    }))

    return (
        <Dropdown menu={{ items }} trigger={['hover']}>
            <Button icon={<LinkOutlined />}>Quick links</Button>
        </Dropdown>
    )
}
