import type {PartnerEmployee} from 'components/index.bff'
import type { PartnerCompany } from '../pages/customer-success/partner/index.bff'
import {
    PartnerDisplayRole,
    PartnerRole,
    PartnerEmployee as SwrPartnerEmployee
} from 'types/employee'

export const getUserDisplayRole = (isAdmin: boolean) =>
    isAdmin ? PartnerDisplayRole.ADMIN : PartnerDisplayRole.MEMBER

export const getUserDisplayRoleByUserRole = (role: string) =>
    role === PartnerRole.OWNER ? PartnerDisplayRole.ADMIN : PartnerDisplayRole.MEMBER

export const getPartnerEmployeeName = (employee: PartnerEmployee | SwrPartnerEmployee) => {
    const { email, firstName = '', lastName = '' } = employee
    return [firstName, lastName].filter(Boolean).join(' ').trim() || email
}

export const filterPartnerEmployeesByNameOrEmail = <T extends PartnerEmployee>(
    employees: T[],
    filter: string
): T[] =>
    employees.filter(
        (e) =>
            getPartnerEmployeeName(e).toLowerCase().includes(filter.toLowerCase()) ||
            e.email.toLowerCase().includes(filter.toLowerCase())
    )

export const getAssignedAndUnassignedBookkeepers = (
    employees: PartnerEmployee[],
    company: PartnerCompany
) => {
    interface BookkeeperAccess {
        bookkeepers: PartnerEmployee[]
        unassignedBookkeepers: PartnerEmployee[]
    }
    const assignedAndUnassignedBookkeepers = employees.reduce(
        (prev: BookkeeperAccess, curr) => {
            if (company.bookkeeperUserIds.includes(curr.userId || '')) {
                prev.bookkeepers.push(curr)
                return prev
            }
            prev.unassignedBookkeepers.push(curr)
            return prev
        },
        { bookkeepers: [], unassignedBookkeepers: [] }
    )
    return assignedAndUnassignedBookkeepers
}
