import type { FC } from 'react'
import { Alert, Breadcrumb } from 'antd'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { PageContentLayout } from 'components/layout-containers'
import Spinner from 'components/content-spinner'
import { SingleDemoAccountEmployeesTable } from './single-demo-account-employees-table'
import { SingleDemoAccountCompanyActionsCard } from './single-demo-account-company-actions-card'
import { demoAccountsBff } from '../bff/demo-accounts-bff'
import type { DemoAccount } from '../bff/index.bff'

export const SingleDemoAccountContainer: FC = () => {
    const { id: demoAccountId } = useParams()

    const {
        isLoading,
        data: demoAccount,
        error,
        refetch: revalidateDemoAccount,
    } = demoAccountsBff.singleAccount.getDemoAccount.useQuery(
        {
            demoAccountId,
        },
        { retry: false }
    )

    if (isLoading && !demoAccount) {
        return (
            <PageContentLayout>
                <Spinner />
            </PageContentLayout>
        )
    }

    if (!demoAccount && error) {
        return (
            <PageContentLayout>
                <Alert
                    data-testid="get-demo-account-error"
                    message={`There was an error fetching demo account with id '${demoAccountId}': ${error.message}`}
                    type="error"
                />
                <RouterLink to={'/product/demo-accounts'}>Go back</RouterLink>
            </PageContentLayout>
        )
    }

    if (!demoAccount) {
        return (
            <PageContentLayout>
                <Alert
                    data-testid="get-demo-account-not-found"
                    message={`Could not find a demo account with id '${demoAccountId}'.`}
                    type="warning"
                />
                <RouterLink to={'/product/demo-accounts'}>Go back</RouterLink>
            </PageContentLayout>
        )
    }

    return (
        <SingleDemoAccountPage
            demoAccount={demoAccount}
            revalidateDemoAccount={revalidateDemoAccount}
        />
    )
}

interface Props {
    demoAccount: DemoAccount
    revalidateDemoAccount?: () => void
}

export const SingleDemoAccountPage: FC<Props> = ({ demoAccount, revalidateDemoAccount }) => {
    return (
        <PageContentLayout>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <RouterLink to={'/product'}>Product</RouterLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <RouterLink to={'/product/demo-accounts'}>Demo Accounts</RouterLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{demoAccount.company.name}</Breadcrumb.Item>
            </Breadcrumb>
            <SingleDemoAccountCompanyActionsCard
                demoAccount={demoAccount}
                revalidateDemoAccount={revalidateDemoAccount}
            />
            <SingleDemoAccountEmployeesTable
                demoAccount={demoAccount}
                revalidateDemoAccount={revalidateDemoAccount}
            />
        </PageContentLayout>
    )
}
