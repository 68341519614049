import { Progress, Space, TableColumnProps, Tag, Tooltip, Typography } from 'antd'
import { green, yellow, orange, red } from '@ant-design/colors'
import {
    AlertOutlined,
    ClockCircleOutlined,
    FlagOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { getEmojiFlag } from 'countries-list'
import { RiskRatingTag } from 'components/risk-rating-tag/risk-rating-tag'
import { formatDate } from 'packages/dates/dates'
import { capitalizeFirstLetter } from 'utils/strings'
import { StatusTag } from './status-tag'
import { ToggleAssigneeButton } from './toggle-assignee'
import type { CaseOutput } from 'bff/moons/generated/case-manager'
import { useOddListContext } from './context-list'
import { ReminderStatusType } from 'pages/compliance/odd/types'

const { Text } = Typography

export const oddStartedColumn: TableColumnProps<CaseOutput> = {
    title: 'ODD Started',
    dataIndex: 'oddStartDate',
    key: 'oddStartDate',
    render: (_, oddCase) => <OddStartedCell {...oddCase} />,
}

const OddStartedCell = ({ assignedTo, id, state }: CaseOutput) => {
    const isLocked = state === 'ODD_COMPLETED' || state === 'OFFBOARDING_COMPLETED'
    const caseHandlerInitials = assignedTo
        ? `${assignedTo.firstName} ${assignedTo.lastName}`
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : ''

    return (
        <Space>
            <ToggleAssigneeButton
                id={id}
                hasAssignee={Boolean(assignedTo)}
                caseHandlerInitials={caseHandlerInitials}
                firstName={assignedTo?.firstName}
                lastName={assignedTo?.lastName}
                isLocked={isLocked}
            />
        </Space>
    )
}

export const dueDateColumn: TableColumnProps<CaseOutput> = {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (dueDate, oddCase) => {
        const getType = () => {
            if (dueDate === null) return undefined
            const today = dayjs()
            const dueDateDayJs = dayjs(dueDate)
            if (today.isAfter(dueDateDayJs)) return 'danger'
            if (today.isAfter(dueDateDayJs.subtract(1, 'w'))) return 'warning'
            if (oddCase.state !== 'ODD_COMPLETED') return 'success'
        }
        const type = getType()
        return <Text type={type}>{formatDate(dueDate)}</Text>
    },
}

export const lastStateChangeAtColumn: TableColumnProps<CaseOutput> = {
    title: (
        <>
            Last State Change{' '}
            <Tooltip title="The last time the case was moved to a different case state">
                <InfoCircleOutlined />
            </Tooltip>
        </>
    ),
    dataIndex: 'lastStateChangeAt',
    key: 'lastStateChangeAt',
    render: (changedAt) => {
        return <Text>{formatDate(changedAt)}</Text>
    },
}

export const totalTimeClosed: TableColumnProps<CaseOutput> = {
    title: (
        <>
            Total time to completion{' '}
            <Tooltip title="Time since case first assigned until last state change (closed)">
                <InfoCircleOutlined />
            </Tooltip>
        </>
    ),
    dataIndex: 'totalTimeClosed',
    key: 'totalTimeClosed',
    render: (_, oddCase) => {
        const calculatedTime = dayjs(oddCase.firstAssignedTo?.at).from(
            dayjs(oddCase.lastStateChangeAt)
        )
        return <Text>Completed {calculatedTime}</Text>
    },
}

export const totalTimeElapsedFromStart: TableColumnProps<CaseOutput> = {
    title: (
        <>
            Time Since Start{' '}
            <Tooltip title="Time since case first assigned until now">
                <InfoCircleOutlined />
            </Tooltip>
        </>
    ),
    dataIndex: 'totalTimeElapsedFromStart',
    key: 'totalTimeElapsedFromStart',
    render: (_, oddCase) => {
        const calculatedTime = dayjs().diff(oddCase.firstAssignedTo?.at, 'd')
        return (
            <Text>
                {oddCase.firstAssignedTo?.at ? `Started ${calculatedTime} days ago` : 'Not started'}
            </Text>
        )
    },
}

export const companyColumn: TableColumnProps<CaseOutput> = {
    title: 'Company',
    dataIndex: 'country',
    key: 'company',
    render: (_, oddCase) => {
        const { metadata, entityId, notes, id, state } = oddCase
        const companyId = entityId
        const {
            companyName,
            companyRegistrationNumber: registrationNumber,
            companyCountryCode,
            offboardingState,
            offboardingDetails,
        } = metadata
        /* 
        offboardingDetails:
        ---
        category: ENUM
        reasons: set<ENUM>
        hasReasons: bool
        notes: string
        initiatedBy: string (email)
        initiatedAt: timestamp
        completedBy: nullable, string (email)
        completedAt: nullable, timestamp 
        */

        const { companyOffboardedModal, companyOffboardedInitiatedModal } = useOddListContext()

        const stateIsCompleted = state === 'ODD_COMPLETED' || state === 'OFFBOARDING_COMPLETED'
        const stateIsOffboardingInitiated = state === 'OFFBOARDING_INITIATED'

        const offboardingDetailsParsed =
            offboardingDetails && offboardingDetails.length
                ? JSON.parse(offboardingDetails)
                : undefined

        const shouldShowAlert =
            offboardingState &&
            !stateIsCompleted &&
            (offboardingState === 'COMPLETED' ||
                (offboardingState === 'INITIATED' && !stateIsOffboardingInitiated))

        return (
            <>
                {shouldShowAlert && (
                    <Tooltip
                        title={
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <strong>Offboarding Details</strong>
                                <div>
                                    <strong>Category:</strong> {offboardingDetailsParsed?.category}
                                </div>
                                <div>
                                    <strong>Reasons:</strong>{' '}
                                    {offboardingDetailsParsed?.hasReasons
                                        ? offboardingDetailsParsed?.reasons.join(', ')
                                        : 'None'}
                                </div>
                                <div>
                                    <strong>Notes:</strong> {offboardingDetailsParsed?.notes}
                                </div>
                                <div>
                                    <strong>Initiated by:</strong>{' '}
                                    {offboardingDetailsParsed?.initiatedBy}
                                </div>
                                <div>
                                    <strong>Initiated at:</strong>{' '}
                                    {formatDate(offboardingDetailsParsed?.initiatedAt)}
                                </div>
                                {offboardingState === 'COMPLETED' && (
                                    <>
                                        {offboardingDetailsParsed?.completedBy && (
                                            <div>
                                                <strong>Completed by:</strong>{' '}
                                                {offboardingDetailsParsed?.completedBy}
                                            </div>
                                        )}
                                        {offboardingDetailsParsed?.completedAt && (
                                            <div>
                                                <strong>Completed at:</strong>{' '}
                                                {formatDate(offboardingDetailsParsed?.completedAt)}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        }
                        overlayStyle={{ minWidth: 'max-content' }}
                    >
                        <>
                            {offboardingState === 'COMPLETED' && (
                                <Tag
                                    color="red"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        companyOffboardedModal.open(id)
                                    }}
                                >
                                    <AlertOutlined /> Offboarded Company
                                </Tag>
                            )}
                            {offboardingState === 'INITIATED' && (
                                <Tag
                                    color="orange"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        companyOffboardedInitiatedModal.open(id)
                                    }}
                                >
                                    <WarningOutlined /> Offboarding Started
                                </Tag>
                            )}
                        </>
                    </Tooltip>
                )}
                <Tooltip
                    title={
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <strong>Company ID</strong>
                            <span style={{ whiteSpace: 'pre' }}>{companyId}</span>
                            {notes && (
                                <div style={{ width: 400 }}>
                                    <strong>Note</strong>
                                    <div>{notes}</div>
                                </div>
                            )}
                        </div>
                    }
                    overlayStyle={{ minWidth: 'max-content' }}
                >
                    <Space direction="vertical" size={'small'}>
                        <Text>{companyName}</Text>
                        <Space size={'small'}>
                            <Tag>
                                {getEmojiFlag(companyCountryCode)} {companyCountryCode}
                            </Tag>
                            <Text type="secondary">{registrationNumber}</Text>
                        </Space>
                    </Space>
                </Tooltip>
            </>
        )
    },
}

export const previousRiskRatingColumn: TableColumnProps<CaseOutput> = {
    title: 'Previous Risk Score',
    dataIndex: 'previousRiskScore',
    key: 'previousRiskScore',
    width: 100,
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { previousRiskScore } = metadata
        return <RiskRatingTag riskRating={previousRiskScore} />
    },
}

export const preliminaryRiskRatingColumn: TableColumnProps<CaseOutput> = {
    title: 'Preliminary Risk Score',
    dataIndex: 'preliminaryRiskScore',
    key: 'preliminaryRiskScore',
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { preliminaryRiskScore } = metadata
        return <RiskRatingTag riskRating={preliminaryRiskScore} isDraft />
    },
    width: 50,
}

export const oddTriggerColumn: TableColumnProps<CaseOutput> = {
    title: 'Trigger',
    dataIndex: 'triggerType',
    key: 'triggerType',
    render: (triggerType) => {
        switch (triggerType) {
            case 'TIME_TRIGGERED':
                return (
                    <Tag icon={<ClockCircleOutlined />} style={{ fontSize: '0.75rem' }}>
                        Time
                    </Tag>
                )
            case 'EVENT_TRIGGERED':
                return (
                    <Tag icon={<FlagOutlined />} style={{ fontSize: '0.75rem' }}>
                        Event
                    </Tag>
                )
            default:
                return (
                    <Tag icon={<FlagOutlined />} style={{ fontSize: '0.75rem' }}>
                        {triggerType && capitalizeFirstLetter(triggerType)}
                    </Tag>
                )
        }
    },
}

export const oddStatusColumn: TableColumnProps<CaseOutput> = {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
    render: (state) => <StatusTag status={state} />,
}

const getReminderCountAsPercentage = (metadata: CaseOutput['metadata']) => {
    switch (metadata['waitStatus']) {
        case ReminderStatusType.WALLET_BLOCKED_DUE:
            return 100
        case ReminderStatusType.REMINDER_2:
            return 75
        case ReminderStatusType.REMINDER_1:
            return 50
        case ReminderStatusType.INITIAL:
            return 25
        default:
            return 0
    }
}

const getReminderCountLabel = (metadata: CaseOutput['metadata']) => {
    switch (metadata['waitStatus']) {
        case ReminderStatusType.WALLET_BLOCKED_DUE:
            return 'Wallet needs to be blocked due to overdue information request'
        case ReminderStatusType.REMINDER_2:
            return '2nd reminder sent to company admins'
        case ReminderStatusType.REMINDER_1:
            return '1st reminder sent to company admins'
        case ReminderStatusType.INITIAL:
            return 'Initial information request sent to company admins'
        default:
            return 'No information request has been made'
    }
}

export const oddWaitingForCustomerColumn: TableColumnProps<CaseOutput> = {
    title: 'Reminders',
    dataIndex: 'metadata',
    key: 'waitingForCustomer',
    render: (metadata) => {
        return (
            <Space>
                <Progress
                    percent={getReminderCountAsPercentage(metadata)}
                    steps={4}
                    showInfo={false}
                    strokeColor={[green[3], yellow[3], orange[3], red[3]]}
                />
                <Tooltip title={getReminderCountLabel(metadata)}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </Space>
        )
    },
}

export const riskRatingColumn: TableColumnProps<CaseOutput> = {
    title: 'Concluded Risk Score',
    dataIndex: 'concludedRiskScore',
    key: 'concludedRiskScore',
    width: 100,
    render: (_, oddCase) => <RiskRatingTag riskRating={oddCase?.metadata?.concludedRiskScore} />,
}

export const lastAssignedToColumn: TableColumnProps<CaseOutput> = {
    title: 'Last Assigned To',
    dataIndex: 'lastAssignedTo',
    key: 'lastAssignedTo',
    render: (lastAssignedTo) => (
        <Text>
            {lastAssignedTo?.firstName} {lastAssignedTo?.lastName}
        </Text>
    ),
}

export const completedAtColumn: TableColumnProps<CaseOutput> = {
    title: 'Completed at',
    dataIndex: 'lastStateChangeAt',
    key: 'completedAtColumn',
    render: (lastStateChangeAt) => (
        <Space>
            <Text>{formatDate(lastStateChangeAt)}</Text>
            <Tooltip title='When the last state changed (completed). Filter by "last state change from/to"'>
                <InfoCircleOutlined />
            </Tooltip>
        </Space>
    ),
}
