import {
    updateOverdraftAccountState,
    useOverdraftAccount,
    useOverdraftApplication,
    useOverdraftEligibility,
} from 'services/deimos/overdraft'
import { useSelector } from 'react-redux'
import { selectCompanyCountry } from 'store/modules/support/company/selectors'
import { Alert, Button, Card, Descriptions, Space, Tag, Tooltip, Typography } from 'antd'
import Spinner from 'components/content-spinner'
import formatCurrency from 'utils/format-currency'
import { isAxiosError } from 'axios'
import type { Account, AccountState } from 'bff/moons/generated/kalyke'
import dayjs from 'packages/dayjs'
import { OverdraftEligibilityModal } from 'pages/customer-success/company/credit/overdraft-eligibility'
import { useState } from 'react'
import HigherLimitApplicationSection from './overdraft-card/higher-limit-application-section/higher-limit-application-section'
import { getOverdraftApplicationStatusColor } from './heplers'
import { selectEmployees } from 'store/modules/employees/selectors'
import EmployeeLink from './overdraft-card/employee-link'

const { Title, Text } = Typography

const useOverdraftSupportedCountry = (country: string) => {
    return {
        isOverdraftSupportedCountry: Boolean(
            ['DE', 'GB', 'SE', 'DK', 'NL', 'FR', 'ES', 'IE', 'BE', 'AT', 'PT'].includes(country)
        ),
    }
}

const getOverdraftAccountStateColor = (state: AccountState | undefined) => {
    switch (state) {
        case 'ACTIVE':
            return 'green'
        case 'GRACE':
            return 'yellow'
        case 'OVERDUE':
            return 'orange'
        case 'COLLECTIONS':
            return 'red'
        default:
            return 'default'
    }
}

const OverdraftSection = ({ children, title }: { children: React.ReactNode; title: string }) => {
    return (
        <Space direction="vertical" style={{ margin: '8px 0' }}>
            <Title level={5} style={{ margin: '8px 0 4px 0' }}>
                {title}
            </Title>
            {children}
        </Space>
    )
}

const OverdraftEligibilitySection = ({ companyId }: { companyId: string }) => {
    const {
        data: overdraftEligibility,
        error: overdraftEligibilityError,
        isLoading: isLoadingOverdraftEligibility,
    } = useOverdraftEligibility(companyId)
    const [isEligibilityModalVisible, setIsEligibilityModalVisible] = useState(false)

    if (isLoadingOverdraftEligibility) {
        return (
            <OverdraftSection title="Eligibility">
                <Spinner />
            </OverdraftSection>
        )
    }

    if (
        !overdraftEligibility ||
        (isAxiosError(overdraftEligibilityError) &&
            overdraftEligibilityError.response?.status === 404)
    ) {
        return (
            <OverdraftSection title="Eligibility">
                <Alert message="Overdraft eligibility data are missing." type="info" />

                <Descriptions.Item>
                    <Button onClick={() => setIsEligibilityModalVisible(true)}>
                        Set eligibility
                    </Button>
                </Descriptions.Item>

                <OverdraftEligibilityModal
                    isVisible={isEligibilityModalVisible}
                    closeModal={() => setIsEligibilityModalVisible(false)}
                />
            </OverdraftSection>
        )
    }
    if (overdraftEligibilityError) {
        return (
            <OverdraftSection title="Eligibility">
                <Alert message="There was an error fetching Overdraft eligibility." type="error" />
            </OverdraftSection>
        )
    }

    const { eligibility, terms } = overdraftEligibility

    return (
        <OverdraftSection title="Eligibility">
            <Descriptions column={4}>
                <Descriptions.Item label="Eligibility">
                    <Tag color={eligibility.eligible ? 'green' : 'default'}>
                        {eligibility.eligible ? 'ELIGIBLE' : 'INELIGIBLE'}
                    </Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Eligible limit">
                    {terms?.limit
                        ? formatCurrency(terms.limit, terms?.currency, {
                              maximumFractionDigits: 0,
                          })
                        : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Interest rate">
                    {terms?.interestRate !== undefined ? `${terms.interestRate}%` : '-'}
                </Descriptions.Item>

                <Descriptions.Item label="Activation fee">
                    {terms?.activationFeePercent !== undefined
                        ? `${terms.activationFeePercent}%`
                        : '-'}
                </Descriptions.Item>

                <Descriptions.Item>
                    <Button onClick={() => setIsEligibilityModalVisible(true)}>
                        Change eligibility
                    </Button>
                </Descriptions.Item>
            </Descriptions>

            <OverdraftEligibilityModal
                isVisible={isEligibilityModalVisible}
                closeModal={() => setIsEligibilityModalVisible(false)}
            />
        </OverdraftSection>
    )
}

const getBillingDate = (account: Account) => {
    if (account.billingType === 'END_OF_MONTH') {
        return dayjs().endOf('month').format()
    }
    throw Error(
        `Failed to get Overdraft billing date: unsupported billing type ${account.billingType}.`
    )
}

const getChargingDate = (account: Account) => {
    const billingDate = getBillingDate(account)
    return account.chargingDateInterval
        ? dayjs(billingDate).add(account.chargingDateInterval, 'days').format()
        : billingDate
}

const OverdraftAccountSection = ({ companyId }: { companyId: string }) => {
    const {
        data: { currentAccount, pendingRenewalAccount },
        error: overdraftAccountError,
        isLoading: isLoadingOverdraftAccount,
        mutate,
    } = useOverdraftAccount(companyId)
    const employees = useSelector(selectEmployees)

    if (isLoadingOverdraftAccount) {
        return (
            <OverdraftSection title="Account">
                <Spinner />
            </OverdraftSection>
        )
    }

    if (overdraftAccountError) {
        return (
            <OverdraftSection title="Account">
                <Alert message="There was an error fetching Overdraft account." type="error" />
            </OverdraftSection>
        )
    }

    if (!currentAccount) {
        return (
            <OverdraftSection title="Account">
                <Alert message="There's no Overdraft account for this company." type="info" />
            </OverdraftSection>
        )
    }

    const handleDebtCollectionAgency = async () => {
        await updateOverdraftAccountState({
            accountId: currentAccount.id,
            state: 'COLLECTIONS',
            subState: currentAccount.subState === 'EXTERNAL' ? undefined : 'EXTERNAL',
        })
        mutate()
    }

    const activatedBy = employees.find((employee) => employee.userId === currentAccount.activatedBy)

    return (
        <OverdraftSection title="Account">
            <Descriptions column={4}>
                <Descriptions.Item label="State">
                    <Tooltip title={dayjs(currentAccount.stateUpdatedAt).format('lll')}>
                        <Tag
                            color={getOverdraftAccountStateColor(currentAccount.state)}
                            // fix changing Tag font size on hover
                            style={{ fontSize: 12 }}
                        >
                            {currentAccount.state ?? '-'}
                        </Tag>
                    </Tooltip>
                    {currentAccount.subState && (
                        <Tooltip title={dayjs(currentAccount.subStateUpdatedAt).format('lll')}>
                            <Tag
                                // fix changing Tag font size on hover
                                style={{ fontSize: 12 }}
                            >
                                {currentAccount.subState}
                            </Tag>
                        </Tooltip>
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Active limit">
                    {formatCurrency(currentAccount.principalAmount, currentAccount.currency, {
                        maximumFractionDigits: 0,
                    })}
                </Descriptions.Item>

                <Descriptions.Item label="Repayments" span={2}>
                    <Tag>{currentAccount.autoRepayment ? 'AUTO' : 'MANUAL'}</Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Billing date">
                    {dayjs(getBillingDate(currentAccount)).format('ll')}
                </Descriptions.Item>

                <Descriptions.Item label="Charging date">
                    {dayjs(getChargingDate(currentAccount)).format('ll')}
                </Descriptions.Item>
                <Descriptions.Item label="Expiry date">
                    {currentAccount.expiryDate ? dayjs(currentAccount.expiryDate).format('ll') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Renewal">
                    <Tag
                        color={pendingRenewalAccount ? 'green' : 'default'}
                        style={{ fontSize: 12 }}
                    >
                        {pendingRenewalAccount ? 'CREATED' : 'N/A'}
                    </Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Activated at">
                    {dayjs(currentAccount.createdAt).format('ll')}
                </Descriptions.Item>
                {activatedBy && (
                    <Descriptions.Item label="Activated by">
                        <EmployeeLink employee={activatedBy} />
                    </Descriptions.Item>
                )}
            </Descriptions>

            <Space direction="vertical">
                <Title level={5}>Debt Collection Agency</Title>
                <Space>
                    <Tooltip
                        title={
                            currentAccount.state === 'COLLECTIONS'
                                ? `Flag customer that has been ${
                                      currentAccount.subState === 'EXTERNAL'
                                          ? 'withdrawn from'
                                          : 'handed over to'
                                  } a Debt Collection Agency.`
                                : 'Only customers in COLLECTIONS state can be handed over to a Debt Collection Agency'
                        }
                    >
                        <Button
                            disabled={currentAccount.state !== 'COLLECTIONS'}
                            onClick={handleDebtCollectionAgency}
                        >
                            {currentAccount.subState === 'EXTERNAL'
                                ? 'Withdrawn from DCA'
                                : 'Sent to DCA'}
                        </Button>
                    </Tooltip>
                    {currentAccount.subState === 'EXTERNAL' && (
                        <Text type="secondary">
                            Sent to DCA on {dayjs(currentAccount.subStateUpdatedAt).format('lll')}
                        </Text>
                    )}
                </Space>
            </Space>
        </OverdraftSection>
    )
}

const OverdraftApplicationSection = ({ companyId }: { companyId: string }) => {
    const {
        data: overdraftApplication,
        error: overdraftApplicationError,
        isLoading: isLoadingOverdraftApplication,
    } = useOverdraftApplication(companyId)

    if (isLoadingOverdraftApplication) {
        return (
            <OverdraftSection title="Application">
                <Spinner />
            </OverdraftSection>
        )
    }

    if (overdraftApplicationError) {
        return (
            <OverdraftSection title="Application">
                <Alert message="There was an error fetching Overdraft application." type="error" />
            </OverdraftSection>
        )
    }

    if (!overdraftApplication) {
        return (
            <OverdraftSection title="Application">
                <Alert message="There's no Overdraft application for this company." type="info" />
            </OverdraftSection>
        )
    }

    // If the application was approved, then we don't want to show duplicate info
    // as all the info will also be available in the "Overdraft Account" section.
    if (overdraftApplication.status === 'APPROVED') {
        return null
    }

    return (
        <OverdraftSection title="Application">
            <Descriptions column={4}>
                <Descriptions.Item label="Status">
                    <Tag color={getOverdraftApplicationStatusColor(overdraftApplication.status)}>
                        {overdraftApplication.status ?? '-'}
                    </Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Requested limit">
                    {formatCurrency(
                        overdraftApplication.amountRequested,
                        overdraftApplication.currency,
                        { maximumFractionDigits: 0 }
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Repayments">
                    <Tag>{overdraftApplication.autoRepayment ? 'AUTO' : 'MANUAL'}</Tag>
                </Descriptions.Item>
            </Descriptions>
        </OverdraftSection>
    )
}

export const OverdraftCard = ({ companyId }: { companyId: string }) => {
    const country = useSelector(selectCompanyCountry)
    const { isOverdraftSupportedCountry } = useOverdraftSupportedCountry(country)

    if (!isOverdraftSupportedCountry) {
        return (
            <Card title="Overdraft">
                <Alert message="Overdraft is not yet supported in company country." type="info" />
            </Card>
        )
    }
    return (
        <Card title="Overdraft">
            <Space direction="vertical">
                <OverdraftEligibilitySection companyId={companyId} />
                <OverdraftApplicationSection companyId={companyId} />
                <OverdraftAccountSection companyId={companyId} />
                <HigherLimitApplicationSection companyId={companyId} />
            </Space>
        </Card>
    )
}
